import React from "react";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";

function ProjectCards(props) {
  const { variation, year, android, ios } = props;
  return (
    <Card className="project-card-view">
      <Row>
        <Col
          md={4}
          lg={3}
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Card.Img variant="top" src={props.imgPath} alt="card-img" />
        </Col>
        <Col
          md={8}
          lg={9}
          className="d-flex"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Card.Body>
            <Card.Title
              className="project-title"
              style={{
                textAlign: "left",
                fontFamily: "DM Sans",
                marginBottom: 15,
              }}
            >
              {props.title}{" "}
            </Card.Title>
            <Col xl={4} lg={4} md={5} sm={4} xs={10} className="yr-type">
              <div className="year-circle">{year}</div>|
              <div className="type-name">{variation}</div>
            </Col>
            <Card.Text style={{ textAlign: "left" }}>
              {props.description}
            </Card.Text>
            <Col
              xl={12}
              lg={12}
              md={10}
              sm={10}
              xs={10}
              style={{ display: "flex" }}
            >
              {android && (
                <a
                  href={android}
                  className="learn-more"
                  style={{ marginRight: "12px" }}
                  target="_blank"
                >
                  Android
                </a>
              )}
              {ios && (
                <a href={ios} className="learn-more" target="_blank">
                  iOS
                </a>
              )}
            </Col>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}
export default ProjectCards;


import React from 'react';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <h1>Privacy Policy for [Your Kitchen App Name]</h1>

                <p><strong>Effective Date:</strong> [Date]</p>

                <h2>Introduction</h2>

                <p>Welcome to [Your Kitchen App Name] ("we," "us," or "our"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our kitchen app. By accessing or using our app, you consent to the practices described in this Privacy Policy.</p>

                <h2>Information We Collect</h2>

                <ol>
                    <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and other contact details when you register for an account or contact us for support.</li>

                    <li><strong>Usage Data:</strong> We collect information about your interactions with the app, including the recipes you view, your cooking preferences, and any feedback you provide.</li>

                    <li><strong>Device Information:</strong> We may collect information about your device, including its type, operating system, unique device identifiers, and mobile network information.</li>

                    <li><strong>Location Information:</strong> With your consent, we may collect your device's location data to provide location-based features, such as finding local grocery stores.</li>
                </ol>

                <h2>How We Use Your Information</h2>

                <p>We use the information we collect for the following purposes:</p>

                <ol>
                    <li><strong>Providing Services:</strong> To deliver and maintain our app's functionality, including creating and managing user accounts, providing personalized content and recommendations, and processing your orders.</li>

                    <li><strong>Improving User Experience:</strong> To analyze usage patterns, troubleshoot technical issues, and enhance the overall user experience.</li>

                    <li><strong>Communication:</strong> To respond to your inquiries, send service-related notifications, updates, and promotional materials, subject to your preferences.</li>

                    <li><strong>Legal Compliance:</strong> To comply with applicable laws, regulations, and legal processes.</li>
                </ol>

                <h2>Information Sharing</h2>

                <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with:</p>

                <ol>
                    <li><strong>Service Providers:</strong> We may engage third-party service providers to assist us in delivering our services. These providers may have access to your personal information but are obligated to protect it as per this Privacy Policy.</li>

                    <li><strong>Legal Obligations:</strong> We may disclose your information when required by law or to protect our rights, privacy, safety, or property, or the public.</li>
                </ol>

                <h2>Data Security</h2>

                <p>We implement reasonable security measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no data transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>

                <h2>Your Choices</h2>

                <p>You have the following choices regarding your information:</p>

                <ol>
                    <li><strong>Account Information:</strong> You can update or delete your account information at any time through the app's settings.</li>

                    <li><strong>Communication Preferences:</strong> You can manage your communication preferences by adjusting your notification settings.</li>

                    <li><strong>Location Data:</strong> You can disable location services for the app in your device settings.</li>
                </ol>

                <h2>Children's Privacy</h2>

                <p>Our app is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected information from a child, please contact us, and we will take appropriate steps to remove the information.</p>

                <h2>Changes to this Privacy Policy</h2>

                <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date. We encourage you to review this Privacy Policy periodically to stay informed about our data practices.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at [Contact Email Address].</p>

                <p>Thank you for using [Your Kitchen App Name]!</p>

                <address>
                    [Your Kitchen App Name]<br />
                    [Physical Address]<br />
                    [Contact Email Address]<br />
                    [Contact Phone Number]
                </address>

                <p><em>Last Updated: [Date]</em></p>
            </div>
        );
    }
}

export default PrivacyPolicy;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/sam4.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col lg={8} md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            Programming captivated me, and I've definitely acquired some knowledge along the way. 🤔
              <br />
              <br />I'm skilled in languages like
              <i>
                <b className="purple"> Javascript, Python, and Java,  </b>
              </i>
              <br />
              <br />
              specializing in Mobile Development for Android and iOS using React Native. My focus revolves around pioneering&nbsp;
              <i>
                <b className="purple">Web Technologies and crafting innovative Products. </b> I'm also drawn to the dynamic realm of{" "}
                <b className="purple">
                  Blockchain.
                </b>
              </i>
              <br />
              <br />
              Utilizing Node.js and contemporary Javascript libraries <b className="purple"> (React.js, Next.js)</b> fuels my 
              <i>
                <b className="purple">
                  {" "}
                  enthusiasm 
                </b>
              </i>
              &nbsp; for creating 
              <i>
                <b className="purple"> exceptional products.</b>
              </i>
            </p>
          </Col>
          <Col lg={4} md={12} className="d-flex justify-content-center align-items-center">
            <Tilt>
            <div className="home2-img">
              <div className="glowing2-circle">
                <div className="home2-image">
                <img
                src={myImg}
                alt="home2 pic"
                className="img-fluid"
                style={{width: "100%", height: "100%"}}
                // style={{ maxHeight: "450px" }}
              />
                </div>
              </div>

              </div>
              </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Samuel-Momoh"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/Samueldan1996"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/samuel-momoh-2a9a92175/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/samuel.momoh.50596/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

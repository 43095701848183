import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import Particle from "../Particle";
import bleepus from "../../Assets/Projects/bleepus.png";
import emachat from "../../Assets/Projects/emachat.webp";
import retail from "../../Assets/Projects/retail.png";
import lectie from "../../Assets/Projects/lectie.svg";
import Rabbit from "../../Assets/Projects/rabbit-shot.png";
import Medscroll from '../../Assets/Projects/medscroll.png';
import Loopscribe from '../../Assets/Projects/loopscribe.webp';

function Projects() {
  return (
    <Container fluid className="project-section" id="project">
      {/* <Particle /> */}
      <div className="flex-start">
        <h1 className="project-heading">
        Featured <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
          </div>
      <Container>


        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={12} className="project-card">
            <ProjectCard
              imgPath={Medscroll}
              title="MedScroll"
              description="Medical Trivia, Quiz, and Games
              Transform your medical learning and teaching experience with MedScroll, the ultimate app specially designed for the medical community. Whether you're studying for exams, refreshing your knowledge, or looking to engage your audience in presentations, MedScroll offers an innovative platform that makes learning both fun and effective. Dive into a vast world of Medical Trivia, customize interactive quizzes, and look forward to exciting AI-powered games. Here’s what makes MedScroll your go-to medical education tool."
              android="https://play.google.com/store/apps/details?id=com.loopscribe.medscroll&pcampaignid=web_share"
              ios="https://apps.apple.com/ng/app/medscroll/id6483944208"
              variation={"Mobile"}
              year={2024}
            />
          </Col>

          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={lectie}
              title="Lectie"
              description="Lectie is a revolutionary mobile application that transforms the traditional calendar into a dynamic community hub, redefining the way we schedule and connect with others. This innovative app seamlessly merges scheduling functionality with social connectivity, creating an immersive ecosystem where every date becomes an opportunity to engage, connect, and celebrate together."
              android="https://play.google.com/store/apps/details?id=com.lectie.vault&pcampaignid=web_share"
              ios="https://apps.apple.com/ng/app/lectie-vault/id1670873307"
              variation={"Mobile"}
              year={2023}
            />
          </Col>

          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={Loopscribe}
              title="Loopscribe"
              description="
              Loopscribe is the notes app that inspires writing,reading,reflection & learning."
              android="https://play.google.com/store/apps/details?id=com.notetakingapp&pcampaignid=web_share"
              ios="https://apps.apple.com/ng/app/loopscribe/id1667459726"
              variation={"Mobile"}
              year={2023}
            />
          </Col>

          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={retail}
              title="FlexDeals"
              description="FlexDeals offers a 'simple-to-use online shopping platform' that helps users discover products in close proximity and have it delivered 'swiftly' at a discounted price.


              From groceries to fashion, baby products, electronics, appliances, beauty, and self-care products, FlexDeals caters for all your everyday needs.
              
              
              FlexDeals gives you access to the best shopping deals in Nigeria. You get up to large discount on your favorite products, as well as multiple payment options, and reliable customer service.
              
              
              Payment has been made easy with our multiple payment options such as pay on delivery, card payments, bank transfers, or with our secured in-app wallet system."
              android="https://play.google.com/store/apps/details?id=com.retailxpress.retailxpress"
              ios="https://apps.apple.com/ng/app/retailxpress-africa/id1673699269"
              variation={"Mobile"}
              year={2023}
            />
          </Col>
          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={bleepus}
              title="Bleepus"
              description="New, smart and reliable way of hiring healthcare providers; and getting healthcare jobs in the UK.
              The only platform that makes healthcare staffing; access to healthcareprofessionals, and healthcare jobs effortlessly for you"
              variation={"Mobile"}
              ios="https://apps.apple.com/ng/app/bleepus/id6473719206"
              android="https://play.google.com/store/apps/details?id=com.bleepus&pcampaignid=web_share"
              year={2023}
            />
          </Col>
          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={Rabbit}
              title="Rabbit Africa"
              description="Rabbit is a brand new service offering users a more comfortable, safe and reliable way to move. We have created an easy-to-use hybrid platform that allows you schedule bus rides and deliveries at your convenience, at a price you probably won’t believe - it’s cheap!"
              android="https://play.google.com/store/apps/details?id=com.rabbit.app&pcampaignid=web_share"
              ios={null}
              variation={"Mobile"}
              year={2023}
            />
          </Col>


          <Col md={12} className="project-card">
            <ProjectCard
              imgPath={emachat}
              title="Emachat"
              description="EmaChat’s mission is to create a separate private space for two; to build a world that belongs to just them. We want to foster their special connection, no matter the distance."
              android="https://play.google.com/store/apps/details?id=com.emachat&pcampaignid=web_share"
              ios={null}
              variation={"Mobile"}
              year={2021}
            />
          </Col>
          {/* <Col md={12} className="project-card">
            <ProjectCard
              imgPath={emotion}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

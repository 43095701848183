import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/sam2.png";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Projects from "../Projects/Projects";
import Github from "../About/Github";
import pdf from "../../Assets/../Assets/Samuel_ Resume.pdf";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row className="align-items-center">
            <Col xl={6} lg={6} md={12} sm={12} className="home-header">
              <h1 className="heading-name">Hi, I'm Samuel Momoh</h1>
              <div style={{ textAlign: "left" }} className="type-writer-con">
                <Type />
              </div>
              <p className="home-des">
                Samuel Momoh is a proficient mobile and MERN stack developer
                with a specialization in React Native. With a keen eye for
                crafting intuitive user experiences, Samuel excels in creating
                dynamic and seamless cross-platform applications. His expertise
                in React Native empowers him to bring innovative ideas to life,
                ensuring top-notch mobile solutions.
              </p>
              <a href={pdf} download="samuel_momoh_resume" rel="noreferrer" target="_blank">
                <button className="home-btn">Download Resume</button>
              </a>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="home-img">
                <div className="glowing-circle">
                  <div className="home-image">
                    <img
                      src={homeLogo}
                      alt="home pic"
                      className="img-fluid"
                      style={{ width: "100%", height: "100%" }}
                      // style={{ maxHeight: "450px" }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Projects />
      <Github />
      <Home2 />
    </section>
  );
}

export default Home;
